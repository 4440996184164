.survey{
    position: relative;
    height: 100vh;
}

.survey .survey-error{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}